<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">

          <a-col :md="8" :sm="24">
            <a-form-item label="数据标签">
              <a-input v-model="queryParam.dictLabel" placeholder="请输入参数名称"/>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="24">
            <a-form-item label="数据键值">
              <a-input v-model="queryParam.dictValue" placeholder="请输入参数键名"/>
            </a-form-item>
          </a-col>

          <a-col :md="!advanced && 8 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetForm">重置</a-button>
            </span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" @click="$refs.actionForm.add(currentId)" icon="plus">新增</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      :columns="columns"
      :data="loadData"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="$refs.actionForm.edit(record)">编辑</a>
        <a-divider type="vertical"/>
        <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => del(record)">
          <a>删除</a>
        </a-popconfirm>
      </span>
      <span slot="statusSwitch" slot-scope="text, record">
        <a-switch
          v-model="record.statusSwitch"
          @change="onStatusChange(record)" />
      </span>
    </s-table>

    <param-modal ref="actionForm" @ok="handleOk"/>
  </a-card>
</template>

<script>
  import { STable } from '@/components'
  import { getAll, del, editStatus } from '@/api/system/dict'
  import ParamModal from './ParamModal'

  export default {
    name: 'ParamTable',
    components: {
      STable,
      ParamModal
    },
    props: {
      currentId: {
        type: String,
        default () {
          return ''
        }
      }
    },
    data () {
      return {
        advanced: false, // 高级搜索 展开/关闭
        queryParam: {}, // 查询参数
        columns: [ // 表头
          // {
          //   title: '分类名称',
          //   dataIndex: 'dirName'
          // },
          {
            title: '字典Key',
            dataIndex: 'dictKey'
          },
          {
            title: '数据标签',
            dataIndex: 'dictLabel'
          },
          {
            title: '数据键值',
            dataIndex: 'dictValue'
          },
          {
            title: '备注',
            dataIndex: 'remark'
          },
          {
            title: '排序号',
            dataIndex: 'sort'
          },
          {
            title: '状态',
            dataIndex: 'statusSwitch',
            scopedSlots: { customRender: 'statusSwitch' }
          },
          {
            title: '操作',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: { customRender: 'action' }
          }
        ],
        // 加载数据方法 必须为 Promise 对象
        loadData: parameter => {
          this.$set(this.queryParam, 'dictTypeId', this.currentId)
          return getAll({
            pageNum: parameter.pageNo,
            pageSize: parameter.pageSize,
            ...this.queryParam
          }).then((res) => {
            res.data = res['rows'].map(val => {
              val.statusSwitch = val.status === 1
              return val
            })
            res.totalCount = res['total']
            res.pageNo = parameter['pageNo']
            return res
          })
        }
      }
    },

    methods: {
      del (record) {
        del({ id: record.id }).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功')
            this.$refs.table.refresh()
          } else {
            this.$message.error('删除失败：' + res.msg)
          }
        }).catch((err) => {
          this.$message.error('删除错误：' + err.msg)
        })
      },
      toggleAdvanced () {
        this.advanced = !this.advanced
      },
      handleOk () {
        this.$refs.table.refresh()
      },
      /**
       * 部门发生变化时刷新
       * @param currentId 选中的部门id
       */
      deptRefresh () {
        this.$refs.table.refresh(true)
      },
      /**
       * 重置表单
       */
      resetForm () {
        this.queryParam = {
          dictLabel: null,
          dictValue: null,
          dictTypeId: this.queryParam.dictTypeId
        }
      },
      // 修改状态
      onStatusChange (row) {
        editStatus({
          id: row.id,
          status: row.status === 1 ? 0 : 1
        }).then(res => {
          if (res.code === 200) {
            this.$message.success('修改成功')
          } else {
            row.statusSwitch = !row.statusSwitch
          }
        })
      }
    }
  }
</script>

<style lang="less">
.table-operator {
  margin-bottom: 2px;
}
button {
  margin-right: 8px;
}
</style>
